import { MaxUint256 } from '@ethersproject/constants'
import JSBI from 'jsbi'
import BigNumber from 'bignumber.js'
import { TransactionResponse } from '@ethersproject/providers'
import { ChainId,Trade, TokenAmount, CurrencyAmount, ETHER,Token,Currency } from 'vvs-sdk'
import { useCallback, useMemo } from 'react'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { ROUTER_ADDRESS } from '../config/constants'
import useTokenAllowance from './useTokenAllowance'
import { Field } from '../state/swap/actions'
import { useTransactionAdder, useHasPendingApproval } from '../state/transactions/hooks'
import { computeSlippageAdjustedAmounts } from '../utils/prices'
import { calculateGasMargin } from '../utils'
import { useTokenContract } from './useContract'
import { useCallWithGasPrice } from './useCallWithGasPrice'


export enum ApprovalState {
  UNKNOWN,
  NOT_APPROVED,
  PENDING,
  APPROVED,
}

// returns a variable indicating the state of the approval and a function which approves if necessary or early returns
export function useApproveCallback(
  amountToApprove?: CurrencyAmount,
  spender?: string,
): [ApprovalState, () => Promise<void>] {
  const { account,chainId } = useActiveWeb3React()
  const { callWithGasPrice } = useCallWithGasPrice()
  const token = amountToApprove instanceof TokenAmount ? amountToApprove.token : undefined
  const currentAllowance = useTokenAllowance(token, account ?? undefined, spender)
  const pendingApproval = useHasPendingApproval(token?.address, spender)

  // check the current approval status
  const approvalState: ApprovalState = useMemo(() => {
    if (!amountToApprove || !spender) return ApprovalState.UNKNOWN
    if (amountToApprove.currency === ETHER && chainId === ChainId.MAINNET ) return ApprovalState.APPROVED
    // we might not have enough data to know whether or not we need to approve
    if (!currentAllowance) return ApprovalState.UNKNOWN

    // console.log(currentAllowance.toFixed())
    // console.log(amountToApprove.toFixed())
    // console.log(currentAllowance.lessThan(amountToApprove))
    // console.log("pendingApproval:",pendingApproval)
    // console.log("currentAllowance.greaterThan(JSBI.BigInt(0)):",currentAllowance.greaterThan(JSBI.BigInt(0)))
    // if (currentAllowance.greaterThan(JSBI.BigInt(0)) ) return ApprovalState.APPROVED
    // amountToApprove will be defined if currentAllowance is
    return currentAllowance.lessThan(amountToApprove)// || currentAllowance.equalTo(amountToApprove)
      ? pendingApproval
        ? ApprovalState.PENDING
        : ApprovalState.NOT_APPROVED
      : ApprovalState.APPROVED
  }, [amountToApprove, currentAllowance, pendingApproval, spender,chainId])

  const tokenContract = useTokenContract(token?.address)
  const addTransaction = useTransactionAdder()

  const approve = useCallback(async (): Promise<void> => {
    if (approvalState !== ApprovalState.NOT_APPROVED) {
      console.error('approve was called unnecessarily')
      return
    }
    if (!token) {
      console.error('no token')
      return
    }

    if (!tokenContract) {
      console.error('tokenContract is null')
      return
    }

    if (!amountToApprove) {
      console.error('missing amount to approve')
      return
    }

    if (!spender) {
      console.error('no spender')
      return
    }

    let useExact = false
    
    const estimatedGas = await tokenContract.estimateGas.approve(spender, MaxUint256).catch((e) => {
      // general fallback for tokens who restrict approval amounts
      // console.log(e)
      // console.log(amountToApprove.raw.toString())
      useExact = true
      return tokenContract.estimateGas.approve(spender, amountToApprove.raw.toString())
    })
    
    // console.log(estimatedGas)
    // console.log(calculateGasMargin(estimatedGas))
    // eslint-disable-next-line consistent-return
    return callWithGasPrice(
      tokenContract,
      'approve',
      [spender, useExact ? amountToApprove.raw.toString() : MaxUint256],
      {
         gasLimit: calculateGasMargin(estimatedGas),
      },
      chainId,
    )
      .then((response: TransactionResponse) => {
        // if (chainId!==ChainId.ETHNET){
          addTransaction(response, {
            summary: `Approve ${amountToApprove.currency.symbol}`,
            approval: { tokenAddress: token.address, spender },
          })
        // }
      })
      .catch((error: Error) => {
        console.error('Failed to approve token', error)
        throw error
      })
  }, [approvalState, token, tokenContract, amountToApprove, spender, addTransaction, callWithGasPrice,chainId])

  return [approvalState, approve]
}

// wraps useApproveCallback in the context of a swap
export function useApproveCallbackFromTrade(trade?: Trade, allowedSlippage = 0) {
  const amountToApprove = useMemo(
    () => (trade ? computeSlippageAdjustedAmounts(trade, allowedSlippage)[Field.INPUT] : undefined),
    [trade, allowedSlippage],
  )

  return useApproveCallback(amountToApprove, ROUTER_ADDRESS)
}

export function useApproveCallbackFromBridge(currency: Currency,amount: string) {
  
  // console.log(currency)
  let maxToken = CurrencyAmount.ether(JSBI.BigInt('0'))
  if (currency){
    const value = new BigNumber(amount.length>0?amount:0).times(new BigNumber(10).pow(currency.decimals))

    maxToken = currency instanceof Token
    ? new TokenAmount(currency, JSBI.BigInt(value?value.toString():'0'))
    : CurrencyAmount.ether(JSBI.BigInt('0'))
  }
  return useApproveCallback(maxToken, process.env.REACT_APP_BRIDGE_ADDRESS_ETHNET)
}

export function useApproveCallbackFromBridgeOnSeele(currency: Currency,amount: string) {
  
  
  let maxToken = CurrencyAmount.ether(JSBI.BigInt('0'))
  if (currency){
    const value = new BigNumber(amount.length>0?amount:0).times(new BigNumber(10).pow(currency.decimals))

    maxToken = currency instanceof Token
    ? new TokenAmount(currency, JSBI.BigInt(value?value.toString():'0'))
    : CurrencyAmount.ether(JSBI.BigInt('0'))
  }
  return useApproveCallback(maxToken, process.env.REACT_APP_BRIDGE_ADDRESS_MAINNET)
}


