import React from 'react'
import styled from 'styled-components'
import { Spinner } from 'components/Spinner'
import Page from '../Layout/Page'

const StyledPageContain = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  background-image: ${({theme}) =>
  `radial-gradient(circle at 100% -80%, ${theme.rainbow})`};
`

const Wrapper = styled(Page)`
  display: flex;
  justify-content: center;
  align-items: center;
`

const PageLoader: React.FC = () => {
  return (
    <StyledPageContain>
    <Wrapper>
      <Spinner />
    </Wrapper>
    </StyledPageContain>
  )
}

export default PageLoader
