import { serializeTokens } from './tokens'
import { SerializedFarmConfig } from './types'

const serializedTokens = serializeTokens()
// FIXME update pid, address and tokens for LP
const farms: SerializedFarmConfig[] = [
  /**
   * These 3 farms (PID 0, 1, 2, 3) should always be at the top of the file.
   */
  {
    pid: 0,
    lpSymbol: 'CSF',
    lpAddresses: {
      338: '0x904Bd5a5AAC0B9d88A0D47864724218986Ad4a3a',
      186: '0x3453c5bEC5dF4278ca0D5b2A60E3F0B6e40c2D58',
    },
    token: serializedTokens.vvs,
    quoteToken: serializedTokens.wseele,
  },
  {
    pid: 1,
    lpSymbol: 'CSF-USDT LP',
    lpAddresses: {
      338: '0x9e3A0434ED428dFDe5ace779ead35e141DD95eFf',
      186: '0x00e2Cb9E062C56496B30Dc46438a7F6eBD1DBb58',
    },
    token: serializedTokens.vvs,
    quoteToken: serializedTokens.usdc,
   },
   {
    pid: 2,
    lpSymbol: 'SEELE-USDT LP',
    lpAddresses: {
      338: '0x5323D7CE6a12282c0448d255d8B5EDc9918E52Da',
      186: '0x742339A6857BB754ac17b7e7a73987D20535D3db',
    },
    token: serializedTokens.usdc,
    quoteToken: serializedTokens.wseele,
  },
  {
    pid: 3,
    lpSymbol: 'SNP-USDT LP',
    lpAddresses: {
      338: '0x9e3A0434ED428dFDe5ace779ead35e141DD95eFf',
      186: '0xC94Ef5bb6166faACf47aE88f73e01b2E08A3baaA',
    },
    token: serializedTokens.snp,
    quoteToken: serializedTokens.usdc,
  }, 
  
  
]

export default farms
