import React from 'react'
import styled from 'styled-components'
import { Skeleton, Text, useTooltip, HelpIcon, Flex, Box, useMatchBreakpoints } from 'vvs-uikit'
import { DeserializedPool } from 'state/types'
import Balance from 'components/Balance'
import { isBlindMode } from 'utils'
import { useVvsVault } from 'state/pools/hooks'
import { useTranslation } from 'contexts/Localization'
import { getVvsVaultEarnings } from 'views/Pools/helpers'
import { getBalanceNumber, getFullDisplayBalance, getDecimalAmount } from 'utils/formatBalance'
import BaseCell, { CellContent } from './BaseCell'


interface AutoEarningsCellProps {
  pool: DeserializedPool
  account: string
  userDataLoaded: boolean
}

const StyledCell = styled(BaseCell)`
  flex: 4.5;
  ${({ theme }) => theme.mediaQueries.sm} {
    flex: 1 0 120px;
  }
`

const HelpIconWrapper = styled.div`
  align-self: center;
`

const AutoEarningsCell: React.FC<AutoEarningsCellProps> = ({ pool, account, userDataLoaded }) => {
  const { t } = useTranslation()
  const { isMobile } = useMatchBreakpoints()
  const { earningTokenPrice } = pool

  const {
    userData: { vvsAtLastUserAction, userShares, lastUserActionTime },
    pricePerFullShare,totalPendingVvsHarvest,
  } = useVvsVault()
  const { hasAutoEarnings, autoVvsToDisplay, autoUsdToDisplay } = getVvsVaultEarnings(
    account,
    vvsAtLastUserAction,
    userShares,
    pricePerFullShare,
    earningTokenPrice,
  )
  const labelText = t('Recent CSF Earned')
  const earningTokenBalance = getBalanceNumber(totalPendingVvsHarvest, 18)
  const hasEarnings = hasAutoEarnings
  const earningTokenDollarBalance = autoUsdToDisplay

  const lastActionInMs = lastUserActionTime && parseInt(lastUserActionTime) * 1000
  const dateTimeLastAction = new Date(lastActionInMs)
  const dateStringToDisplay = dateTimeLastAction.toLocaleString()

  const { targetRef, tooltip, tooltipVisible } = useTooltip(
    <>
      <Balance fontSize="16px" value={autoVvsToDisplay} decimals={3} bold unit=" CSF" />
      {!isBlindMode() && <Balance fontSize="16px" value={autoUsdToDisplay} decimals={2} bold prefix="~$" />}
      {t('Earned since your last action')}
      <Text>{dateStringToDisplay}</Text>
    </>,
    { placement: 'bottom' },
  )

  return (
    <StyledCell role="cell">
      <CellContent>
        <Text fontSize="12px" color="textSubtle" textAlign="left">
          {labelText}
        </Text>
          <>
            {tooltipVisible && tooltip}
            <Flex>
              <Box mr="8px" height="32px">
                <Balance
                  mt="4px"
                  bold={!isMobile}
                  fontSize={isMobile ? '14px' : '16px'}
                  color= 'primary'
                  decimals={ 5 }
                  value={ earningTokenBalance }
                />
                
                  <>
                    {earningTokenPrice > 0 && (
                      <Balance
                        display="inline"
                        fontSize="12px"
                        color="textSubtle"
                        decimals={2}
                        prefix="~"
                        value={getBalanceNumber(totalPendingVvsHarvest.times(earningTokenPrice),18)}
                        unit=" USD"
                      />
                    )}
                  </>

              </Box>
              {hasEarnings && !isMobile && (
                <HelpIconWrapper ref={targetRef}>
                  <HelpIcon color="textSubtle" />
                </HelpIconWrapper>
              )}
            </Flex>
          </>
        
      </CellContent>
    </StyledCell>
  )
}

export default AutoEarningsCell
