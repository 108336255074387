import { ChainId, Token } from 'vvs-sdk'
import { serializeToken } from 'state/user/hooks/helpers'
import { SerializedToken } from './types'

const { MAINNET, TESTNET,ETHNET } = ChainId

interface TokenList {
  [symbol: string]: Token
}

interface SerializedTokenList {
  [symbol: string]: SerializedToken
}

export const mainnetTokens = {
  workbench: new Token(
    MAINNET,
    process.env.REACT_APP_WORKBENCH_ADDRESS_MAINNET,
    18,
    'WORKBENCH',
    'WORKBENCH',
    'https://vvs.finance/',
  ),
  cro: new Token(
    MAINNET,
    '0xe4f0A824B2B38F0b8B788dDc25CcD18AECa2d9bD',
    18,
    'SEELE',
    'SEELE Token',
    'https://seelen.pro/',
  ),
  wseele: new Token(
    MAINNET,
    '0xe4f0A824B2B38F0b8B788dDc25CcD18AECa2d9bD',
    18,
    'WSEELE',
    'Wrapped SEELE',
    'https://seelen.pro/',
  ),
  vvs: new Token(
    MAINNET,
    '0x3453c5bEC5dF4278ca0D5b2A60E3F0B6e40c2D58',
    18,
    'CSF',
    'CSF Token',
    'https://candyswap.finance/'
  ),
  snp: new Token(
    MAINNET,
    '0x2ABf07aCfe206A5fa1C4827E011ffCb3ffc75696',
    18,
    'SNP',
    'SNP',
    'https://seelen.pro/',
  ),
  usdc: new Token(
    MAINNET,
    '0x008D7923Fe2941Ceb549bf5646B1ddb1aC93C8a6',
    6,
    'USDT',
    'USDT Coin',
    'https://www.circle.com/en/usdc',
  ),

  ethseele: new Token(
    1,
    '0xB1e93236ab6073fdAC58adA5564897177D4bcC43',
    18,
    'SEELE',
    'SEELE',
    'https://seelen.pro/',
  ),
  
}

// FIXME tokens for testnet and mainnet
export const testnetTokens = {
  workbench: new Token(
    TESTNET,
    process.env.REACT_APP_WORKBENCH_ADDRESS_TESTNET,
    18,
    'WORKBENCH',
    'WORKBENCH',
    'https://vvs.finance/',
  ),
  cro: new Token(
    TESTNET,
    '0xe4f0A824B2B38F0b8B788dDc25CcD18AECa2d9bD',
    18,
    'SEELE',
    'SEELE',
    'https://seelen.pro/',
  ),
  wseele: new Token(
    TESTNET,
    '0xe4f0A824B2B38F0b8B788dDc25CcD18AECa2d9bD',
    18,
    'WSEELE',
    'Wrapped SEELE',
    'https://crypto.org/',
  ),
  vvs: new Token(
    TESTNET,
    '0x904Bd5a5AAC0B9d88A0D47864724218986Ad4a3a',
    18,
    'CSF',
    'CSF',
    'https://vvs.finance/'),
  snp: new Token(
    TESTNET,
      '0x2ABf07aCfe206A5fa1C4827E011ffCb3ffc75696',
      18,
      'SNP',
      'SNP',
      'https://seelen.pro/',
    ),
  usdc: new Token(
    TESTNET,
    '0x917112698451ac85d8304a36509cac52b78b0907',
    6,
    'USDC',
    'USD Coin',
    'https://www.circle.com/en/usdc',
  ),
}


export const ethnetTokens = {
  
  cro: new Token(
    MAINNET,
    '0xe4f0A824B2B38F0b8B788dDc25CcD18AECa2d9bD',
    18,
    'SEELE',
    'SEELE Token',
    'https://seelen.pro/',
  ),
  seele: new Token(
    ETHNET,
    '0xB1e93236ab6073fdAC58adA5564897177D4bcC43',
    18,
    'SEELE',
    'SEELE',
    'https://seelen.pro/',
  ),
  
  snp: new Token(
    ETHNET,
    '0x795dBF627484F8248D3d6c09c309825c1563E873',
    18,
    'SNP',
    'SNP',
    'https://seelen.pro/',
  ),
  usdc: new Token(
    ETHNET,
    '0xdAC17F958D2ee523a2206206994597C13D831ec7',
    6,
    'USDT',
    'USDT Coin',
    'https://www.circle.com/en/usdc',
  ),
  
}

const tokens = (): TokenList => {
  const chainId = process.env.REACT_APP_CHAIN_ID

  // If testnet - return list comprised of testnetTokens wherever they exist, and mainnetTokens where they don't
  if (parseInt(chainId, 10) === ChainId.TESTNET) {
    // return Object.keys(mainnetTokens).reduce((accum, key) => {
    //   return { ...accum, [key]: testnetTokens[key] || mainnetTokens[key] }
    // }, {})
    return testnetTokens; // FIXME avoid this mainnet override testnet hack
  }

  if (parseInt(chainId, 10) === ChainId.ETHNET) {
    return ethnetTokens;
  }

  return mainnetTokens
}

export const serializeTokens = (): SerializedTokenList => {
  const unserializedTokens = tokens()
  const serializedTokens = Object.keys(unserializedTokens).reduce((accum, key) => {
    return { ...accum, [key]: serializeToken(unserializedTokens[key]) }
  }, {})

  return serializedTokens
}

export default tokens()
