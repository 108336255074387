import { createAction } from '@reduxjs/toolkit'

export enum Field {
  INPUT = 'INPUT',
  OUTPUT = 'OUTPUT',
}

export const selectCurrency = createAction<{ field: Field; currencyId: string }>('withdraw/selectCurrency')
export const typeInput = createAction<{ field: Field; typedValue: string }>('withdraw/typeInput')
export const replaceSwapState = createAction<{
  field: Field
  typedValue: string
  inputCurrencyId?: string
  outputCurrencyId?: string
  recipient: string | null
}>('withdraw/replaceSwapState')
export const setRecipient = createAction<{ recipient: string | null }>('withdraw/setRecipient')
