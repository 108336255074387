import { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'state'
import { ChainId } from 'vvs-sdk'
import useIsWindowVisible from 'hooks/useIsWindowVisible'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { simpleRpcProvider,simpleEthRpcProvider } from 'utils/providers'
import { setBlock } from '.'
import { State } from '../types'
import getRpcUrl from '../../utils/getRpcUrl'

export const usePollBlockNumber = () => {
  const timer = useRef(null)
  const dispatch = useAppDispatch()
  const isWindowVisible = useIsWindowVisible()
  const { account,chainId } = useActiveWeb3React()

  const RPC_URL = getRpcUrl()

  useEffect(() => {
    if (isWindowVisible) {
      timer.current = setInterval(async () => {
        let blockNumber = 0
        if(chainId===ChainId.ETHNET){
          blockNumber = await simpleEthRpcProvider.getBlockNumber()
        }else{
          blockNumber = await simpleRpcProvider.getBlockNumber()
        }
        // const blockNumber = await simpleRpcProvider.getBlockNumber()
        // console.log("blockNumber:",blockNumber)
        dispatch(setBlock(blockNumber))
      }, chainId===ChainId.ETHNET?10000:4000)
    } else {
      clearInterval(timer.current)
    }

    return () => clearInterval(timer.current)
  }, [dispatch, timer, isWindowVisible,RPC_URL,chainId])
}

export const useBlock = () => {
  return useSelector((state: State) => state.block)
}

export const useInitialBlock = () => {
  return useSelector((state: State) => state.block.initialBlock)
}
