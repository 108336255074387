import sample from 'lodash/sample'

// Array of available nodes to connect to
export const nodes = [
    "https://rpc.candyswap.finance",
    "https://rpc2.candyswap.finance",
  //  "https://rpc3.candyswap.finance:8545",
  //   "https://rpc2.seelen.pro",
  //  "http://123.58.220.93:8545",  // 香港
     process.env.REACT_APP_NODE_1,
  // process.env.REACT_APP_NODE_2,
  // process.env.REACT_APP_NODE_3,
  // process.env.REACT_APP_NODE_4,
]

// Array of available nodes to connect to
export const ethnodes = [
  "https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
  "https://mainnet.infura.io/v3/d6958d53ccf34a559c2bce73d79a71d9",
]


export const getNodeUrl = () => {
  return sample(nodes)
}


export const getEthNodeUrl = () => {
  return sample(ethnodes)
}

export default getNodeUrl
