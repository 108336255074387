import { ethers } from 'ethers'
import {getNodeUrl,getEthNodeUrl} from 'utils/getRpcUrl'

const RPC_URL = getNodeUrl()
const ETHRPC_URL = getEthNodeUrl()

export const simpleRpcProvider = new ethers.providers.JsonRpcProvider(RPC_URL)
export const simpleEthRpcProvider = new ethers.providers.JsonRpcProvider(ETHRPC_URL)

export default null
