// import BigNumber from 'bignumber.js'
import { DEFAULT_GAS_LIMIT, DEFAULT_TOKEN_DECIMAL } from 'config'
import getGasPrice from 'utils/getGasPrice'

const options = {
  gasLimit: DEFAULT_GAS_LIMIT,
}

export const bridgeDeposit = async (bridgeContract, tokenContract,dest,amount) => {
  // const gasPrice = getGasPrice()
  const tx = await bridgeContract.sendToSeele( tokenContract,dest,amount)
  const receipt = await tx.wait()
  return receipt.status
}

export const bridgeOnSeeleDeposit = async (bridgeContract, tokenContract,dest,amount) => {
  // const gasPrice = getGasPrice()
  console.log(bridgeContract);
  console.log(tokenContract);
  console.log(dest);
  console.log(amount);
  const overrides = {
    value: amount
  };
  try{
    if(tokenContract==="0x0000000000000000000000000000000000000000"){
      const tx = await bridgeContract.sendToEth( tokenContract,dest,amount,overrides)
      const receipt = await tx.wait()
      return receipt.status
    }
    const tx = await bridgeContract.sendToEth( tokenContract,dest,amount)
    const receipt = await tx.wait()
    return receipt.status
  } catch (e){
    console.log(e);
    return false;
  }
}

